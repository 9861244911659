import { ISelectOption } from 'common/models'

export const APPLICATION_ID = {
  SYSTEM_ADMINISTRATION: 'ce508b71-4073-4a74-a6e7-8856d6b36490',
  VENDOR_PORTAL: 'e3d1705d-b3b9-47a1-9faa-6b12ea9d8cd0',
  AP_AUTOMATION: '7ac05082-e0e9-466c-aabd-53df9ece7988',
  E_INVOICING: '73d18d49-1a80-4912-8f55-2cc6e3c17b18',
  WHITELIST: '3e03990c-f318-4c31-ad46-0c6a91d5102f',
  WORKFLOWS: 'a09f23e2-8c39-48d4-8d66-8fad6e2c6e2b',
  ICR: '7dde9d5c-8bdc-47fd-adcd-c6d7c078874a',
}

export const EMPTY_SELECT_OPTION: ISelectOption = {
  id: '',
  label: '',
  value: '',
}

export const stringToColor = (string: string) => {
  let hash = 0
  let i

  for (i = 0; i < string.length; i += 1) {
    hash = string.charCodeAt(i) + ((hash << 5) - hash)
  }

  let color = '#'

  for (i = 0; i < 3; i += 1) {
    const value = (hash >> (i * 8)) & 0xff
    color += `00${value.toString(16)}`.slice(-2)
  }

  return color
}

export const stringAvatar = (name: string) => {
  return {
    sx: {
      bgcolor: stringToColor(name),
    },
    children: `${name.split(' ')[0][0]}${name.split(' ')[1][0]}`,
  }
}

export const loadStaticFromPublic = (filename: string): string => {
  return import.meta.env.DEV ? `/public/${filename}` : `/${filename}`
}

type SearchParams = Record<string, string | string[]>

export const extractSearchParams = (searchParams: URLSearchParams): SearchParams => {
  const params: SearchParams = {}

  for (const key of searchParams.keys()) {
    const values = searchParams.getAll(key)
    params[key] = values.length > 1 ? values : values[0]
  }

  return params
}

export const getApplicationLogoPath = () => {
  const applicationId = localStorage.getItem('application_id')

  switch (applicationId) {
    case APPLICATION_ID.WHITELIST:
      return {
        logoUrl: loadStaticFromPublic('apps/whitelist-white-blue-logo.svg'),
        height: 28,
      }
    case APPLICATION_ID.WORKFLOWS:
      return {
        logoUrl: loadStaticFromPublic('apps/smart-approvals-white-blue-logo.svg'),
        height: 28,
      }
    default:
      return {
        logoUrl: loadStaticFromPublic('apps/xelcode-white-blue-logo.svg'),
      }
  }
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const hasProperty = <T extends object, K extends keyof any>(
  obj: T,
  key: K,
): boolean => {
  return key in obj
}
